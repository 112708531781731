import React from 'react';
import { IonContent, IonPage, useIonAlert, useIonToast } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { Form, Input } from 'antd';
import { Button } from 'react-bootstrap';
import { axius, func, images, types } from 'utils';
import { GoBack, IonLoading, PasswordStrength, PhoneNumber } from 'components';
import { _store } from '_Store';


interface Props extends RouteComponentProps, types.StateProps { };
const Reset: React.FC<Props> = props => {

    const { __data: { phones } } = _store.useState();

    const [form] = Form.useForm();
    const [presentAlert] = useIonAlert();
    const [presentToast] = useIonToast();

    const [pwd, setPassword]: any = React.useState('');
    const [step, setStep] = React.useState(1);
    const [type, setType] = React.useState('email');
    const [loading, setLoading] = React.useState('');
    const [strongPWD, setStrongPWD] = React.useState(false);
    const [phoneNumber, setPhoneNumber] = React.useState('');

    const submit = (v: any) => {
        step === 1 ? submitSendCode(v) : submitReset(v);
    }

    const submitSendCode = (v: any) => {
        setLoading('sending code...');
        axius.post('auth/reset', {
            username: v.email || `${phones[v.country.toLowerCase()].code}${v.phone_number.replace(/^0/, '')}`,
        }).then((res: types.AxiusResponse) => {
            setLoading('');
            if (res.status === 200) {
                func.init();
                if (step === 1) {
                    setStep(2);
                    setPhoneNumber(v.phone_number);
                } else {
                    presentToast('Your password has been changed. You can now login.', 1500);
                    func.redirect('/');
                }
            } else {
                if (res.status === 412) {
                    presentAlert({ message: res.data.join('<br />'), });
                } else {
                    presentAlert({ message: res.message, });
                }
            }
        });
    }

    const submitReset = async (v: any) => {
        if (strongPWD) {
            setLoading('pls wait...');
            v['username'] = phoneNumber;
            axius.put('auth/reset', v).then((res: types.AxiusResponse) => {
                setLoading('');
                if (res.status === 200) {
                    func.init();
                    presentToast('Your password has been changed. You can now login.', 1500);
                    func.redirect('/');
                } else {
                    if (res.status === 412) {
                        presentAlert({ message: res.data.join('<br />'), });
                    } else {
                        presentAlert({ message: res.message, });
                    }
                }
            });
        } else {
            presentAlert({ message: 'Please select a strong password', });
        }
    }

    const confirmPassword = (rule: any, value: any, callback: any) => {
        if (!value) {
            callback();
        }
        if (value !== form.getFieldValue('password')) {
            callback('The passwords provided don\'t match. Please enter them again.');
        }
        callback();
    };

    return (
        <IonPage id="Reset">
            <IonContent fullscreen={false}>
                <IonLoading open={loading ? true : false} message={loading} onCancel={() => setLoading('')} />
                {step === 1 && (
                    <div className="p-4">
                        <div className="mb-4">
                            <GoBack {...props} />
                        </div>
                        <img className="mb-2" src={images.Logo} alt="Chicken Republic" style={{ width: 50 }} />
                        <h2 className="mb-2"><b>Forgot Password?</b></h2>
                        <div className="text-muteds">Enter the phone number or email associated with your account and we'll send a message with instructions to reset your password.</div>
                        {/* <div>&nbsp;</div> */}

                        <Form form={form} layout="vertical" onFinish={submit} className="mt-4">
                            {type === 'phone' && (
                                <PhoneNumber {...props} form={form} />
                            )}
                            {type === 'email' && (
                                <Form.Item label="Email address" name="email" rules={[{ required: true, message: 'Field is required' },]}>
                                    <Input type="email" size="large" placeholder="arinola@gmail.com" />
                                </Form.Item>
                            )}
                            <div>&nbsp;</div>

                            <Button type="submit" variant="secondary btn-block btn-md" style={{ height: 49 }}>
                                Submit
                            </Button>

                            {type === 'phone' && (
                                <h5 className="m-0 ins fw-600 primary mt-5" onClick={() => setType('email')}>RESET WITH EMAIL</h5>
                            )}
                            {type === 'email' && (
                                <h5 className="m-0 ins fw-600 primary mt-5" onClick={() => setType('phone')}>RESET WITH PHONE NUMBER</h5>
                            )}
                        </Form>
                    </div>
                )}

                {step === 2 && (
                    <div className="p-4">
                        <div className="mb-4">
                            <GoBack {...props} />
                        </div>
                        <img className="mb-2" src={images.Logo} alt="Chicken Republic" style={{ width: 50 }} />
                        <h3 className="mb-2"><b>Create New Password</b></h3>
                        <div className="text-muteds">Your new password must be different from previous used passwords.</div>
                        <div>&nbsp;</div>

                        {/* {errMsg && (<Alert variant="danger" className="text-center">{errMsg}</Alert>)} */}

                        <Form form={form} layout="vertical" onFinish={submit} className="mt-4">
                            <Form.Item label="Reset code" name="code" rules={[{ required: true, message: 'Code is required' },]}>
                                <Input type="number" size="large" placeholder="• • • • • • • •" />
                            </Form.Item>
                            <Form.Item label="New password" name="password" rules={[{ required: true, message: 'Field is required' },]}>
                                <Input.Password type="password" size="large" placeholder="• • • • • • • •" onChange={e => setPassword(e.target.value)} />
                            </Form.Item>
                            <Form.Item label="Confirm password" name="passwordConfirm" rules={[
                                { required: true, message: 'Field is required' },
                                { validator: confirmPassword }
                            ]}>
                                <Input.Password type="password" size="large" placeholder="• • • • • • • •" onChange={e => setPassword(e.target.value)} />
                            </Form.Item>
                            <PasswordStrength {...props} value={pwd} onChange={(e: boolean) => setStrongPWD(e)} />
                            <div>&nbsp;</div>

                            <Button type="submit" variant="secondary btn-block btn-md text-uppercases" style={{ height: 49 }}>
                                Submit
                            </Button>
                        </Form>
                    </div>
                )}
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
            </IonContent>
        </IonPage>
    );
}

export default Reset;