import React from 'react';
import Radio from './Radio';

interface StateProps {
    label: any;
    color?: 'primary' | 'secondary';
    selected: boolean;
    onClick: any;
    className?: string;
};
interface Props extends StateProps { };

const CustomCheckbox: React.FC<Props> = ({ color, label, selected, onClick }) => {

    return (
        <React.Fragment>
            <div id="CustomCheckbox" className={`p-4s mb-3s d-flex align-items-center pointer ${selected ? 'selected' : ''}`} onClick={() => onClick()}>
                <Radio shape="checkbox" color={color} selected={selected} height={25} />
                <span className={`ml-3 fs-14 ${selected ? 'fw-500s' : ''}`}>{label}</span>
            </div>
        </React.Fragment>
    );
}

export default CustomCheckbox;
