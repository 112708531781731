import React from 'react';
import Radio from './Radio';

interface StateProps {
    label: any;
    color?: 'primary' | 'secondary';
    onClick: any;
    selected: boolean;
    className?: string;
};
interface Props extends StateProps { };

const CustomRadio: React.FC<Props> = ({ label, color, selected, onClick, className }) => {

    return (
        <React.Fragment>
            <div id="CustomRadio" className={`${className || 'p-3s mb-3s'} d-flex align-items-center pointer animate__animated animate__fadeIn ${selected ? 'selected' : ''}`} onClick={() => onClick()}>
                <Radio color={color} selected={selected} height={25} />
                <span className={`ml-3 fs-14 ${selected ? 'fw-400' : ''}`}>{label}</span>
            </div>
        </React.Fragment>
    );
}

export default CustomRadio;