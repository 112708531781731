import React from 'react';
import { func, types } from 'utils';
import CustomRadio from './CustomRadio';
import CustomCheckbox from './CustomCheckbox';

interface StateProps {
    v: any;
    id: string;
    variant: types.Variant;
    onChange: (v: any) => void;
}
interface Props extends StateProps { };

const ItemVariant: React.FC<Props> = ({ v, id, variant, onChange, }) => {

    const io = variant;

    const [value, setValue] = React.useState<any>({});

    React.useEffect(() => {
        setValue(v.variants);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        onChange(value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const onSelect = (data: {
        min: number;
        max: number;
        name: string;
        code: string;
        price: number;
        option: string;
        quantity: number;
    }, multi = false) => {
        const vv = JSON.parse(JSON.stringify(value));
        const { min, max, name, code, price, option, quantity } = data;
        vv[name] = vv[name] || [];
        if (multi) {
            const qty = vv[name].reduce((a: number, b: any) => a + b.quantity, 0) || 1;
            const selected = vv[name].find((s: any) => s.code === code);
            if (selected) {
                if (quantity > 0) {
                    const i = vv[name].indexOf(selected);
                    if (qty < max) {
                        vv[name][i]['quantity'] = quantity;
                    } else {
                        const vvv = JSON.parse(JSON.stringify(vv));
                        vvv[name][i]['quantity'] = quantity;
                        const qqty = vvv[name].reduce((a: number, b: any) => a + b.quantity, 0) || 1;
                        if (qqty < max) {
                            vv[name][i]['quantity'] = quantity;
                        }
                    }
                } else {
                    vv[name] = vv[name].filter((s: any) => s.code !== code);
                }
            } else {
                if (min >= qty && qty < max) {
                    vv[name].push({ code, price, option, quantity: quantity || 1 });
                }
            }
        } else {
            vv[name][0] = { code, price, option, quantity: quantity || 1 };
        }
        setValue(vv);
    }

    return (
        <React.Fragment>
            <div id="ItemVariant" className={``}>
                <div className="mt-4 side p-3">
                    <div className="animate__animated animate__fadeIn">
                        <div className="fw-600 text-uppercases">Select {io.name}</div>
                        {io.min > 1 && (
                            <div className="small text-muted">At least {io.min}. Not more than {io.max}.</div>
                        )}
                    </div>
                    <div className="grid mt-3">
                        {io.options.map((iop, i) => {
                            const name = id;
                            const selected = (value[name] || []).find((s: typeof iop) => s.code === iop.code);
                            return (
                                <div key={`${name}-${iop.option}`}>
                                    <div className="px-3s py-2 d-flex align-items-center justify-content-between animate__animated animate__fadeIn">
                                        <div className="pr-2" style={{ width: +iop.price > 0 ? '75%' : '100%' }}>
                                            {+io.min > 1 ? (
                                                <div>
                                                    <CustomCheckbox
                                                        label={func.ucWords(iop.option)}
                                                        color="primary"
                                                        selected={selected ? true : false}
                                                        onClick={() => onSelect({ ...io, ...iop, name, quantity: 0 }, true)}
                                                    />
                                                </div>
                                            ) : (
                                                <CustomRadio
                                                    label={func.ucWords(iop.option)}
                                                    color="primary"
                                                    selected={selected ? true : false}
                                                    onClick={() => onSelect({ ...io, ...iop, name, quantity: 0 }, false)}
                                                />
                                            )}
                                        </div>
                                        {+iop.price > 0 && (
                                            <div className={`text-right primary`}>
                                                +₦{func.numberFormat(iop.price)}
                                            </div>
                                        )}
                                    </div>
                                    {selected && +io.min > 1 && (
                                        <div
                                            className="mt-2 mb-2 d-flex justify-content-between border py-2 px-3 bg-white"
                                            style={{ width: '20vw', borderRadius: '45px' }}
                                        >
                                            <div onClick={() => onSelect({ ...io, ...iop, name, quantity: selected.quantity - 1 }, true)}>-</div>
                                            <div>{selected.quantity}</div>
                                            <div onClick={() => onSelect({ ...io, ...iop, name, quantity: selected.quantity + 1 }, true)}>+</div>
                                        </div>
                                    )}
                                    {((i + 1) < io.options.length) && (
                                        <div style={{ borderBottom: '1px solid rgba(249, 178, 0, .2)', marginTop: 0 }} />
                                    )}
                                </div>
                            )
                        })}
                    </div>
                    {/* <p>&nbsp;</p> */}
                </div>
            </div>
        </React.Fragment>
    );
}

export default ItemVariant;