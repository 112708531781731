import React from 'react';
import { images } from 'utils';

interface ContainerProps {
    style?: any;
}

const Loading: React.FC<ContainerProps> = ({ style }) => {

    return (
        <React.Fragment>
            <div id="Loading" className="d-flex justify-content-center align-items-center" style={{ height: '100dvh', opacity: '.6', ...style }}>
                <img src={images.Logo} alt="Logo" height="100" className="animate__animated animate__pulse animate__infinite" />
            </div>
        </React.Fragment>
    );
}

export default Loading;