
interface StateProps {
    shape?: 'radio' | 'checkbox';
    height: number;
    color?: 'primary' | 'secondary';
    selected: boolean;
    className?: string;
};
interface Props extends StateProps { };

const Radio: React.FC<Props> = ({ shape, color, selected, height }) => {
    return (
        <div id="Radio" className={`${color || 'primary'} ${shape || 'radio'}`}>
            <div className="d-flex justify-content-center align-items-center" style={{ height, width: height }}>
                {selected && (<div style={{ height: height - 9, width: height - 9 }} />)}
            </div>
        </div>
    )
}

export default Radio;