import React from 'react';
import { useIonAlert, } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { func, types, images, cartFx } from 'utils';
import { Button, ButtonGroup } from 'react-bootstrap';
import { CustomRadio } from 'components';
import moment from 'moment';
import { _store, _update } from '_Store';

interface StateProps {
    checkout: any;
    segments?: any;
    minCartValue: boolean;
}
interface Props extends RouteComponentProps, types.StateProps, StateProps { };
const Cart2: React.FC<Props> = props => {

    const [presentAlert] = useIonAlert();

    const { checkout, minCartValue } = props;
    const { __data: { cart, location, options, active_venue, delivery_partners }, } = _store.useState();

    const distance = +func.distanceMatrix(
        { lat: location?.address?.lat, lng: location?.address?.lng },
        { lat: active_venue?.address.lat, lng: active_venue?.address.lng, },
        true
    );
    const deliveries = distance > +active_venue.delivery.radius ? Object.keys(delivery_partners) : ['cr'];

    React.useEffect(() => {
        if (cart.delivery?.channel) {
            cart['deliveryFee'] = cartFx.deliveryFee(cart.delivery?.channel);
            _update.data('cart', cart);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart.delivery?.channel]);

    const deliveryTime = {
        from: active_venue.delivery?.schedule?.from || '09:00',
        to: active_venue.delivery?.schedule?.to || '20:00',
    };

    return (
        <React.Fragment>
            <div id="Cart" className="content c-small">
                <div>&nbsp;</div>
                <div id="Segments" className="mb-3 d-flex justify-content-center">
                    <ButtonGroup>
                        {(props.segments || options)
                            .filter((seg: any) => (
                                moment().isBetween(func.dates.dt + ' ' + deliveryTime.from, func.dates.dt + ' ' + deliveryTime.to)
                                || seg.key === 'pickup'
                            ))
                            .map((seg: any) => (
                                <Button
                                    key={seg.key} variant={location.option === seg.key ? 'active' : ''}
                                    onClick={() => _update.data('location', { ...location, option: seg.key })}
                                >
                                    {seg.label}
                                </Button>
                            ))}
                    </ButtonGroup>
                </div>
                <div className="divider" />

                {location.option === 'pickup' && (
                    <div className="p-4">
                        <div className="p-3 alert alert-warning">
                            <div className="fw-600" style={{ color: '#000' }}>Pick up Location</div>
                            <div className="primary d-flex align-items-center">
                                <img src={images.Location2} alt="Location" height={15} />
                                <div className="ml-2">{active_venue.address.name}</div>
                            </div>
                        </div>
                        <div className="dividers mb-3" />
                        <div>&nbsp;</div>
                        <Button type="submit" variant="secondary btn-block btn-md text-uppercases" style={{ height: 49 }} onClick={checkout}>
                            Continue
                        </Button>
                    </div>
                )}

                {location.option === 'delivery' && (
                    <div className="p-4">
                        {deliveries[0] !== 'cr' && (
                            <div className="text-uppercases primary fw-600 mb-4">Delivery Partners xxxx</div>
                        )}

                        {deliveries.map((del, d) => (
                            <div key={del} className="mb-4">
                                <CustomRadio
                                    label={(del === 'cr' ? 'Chicken Republic' : del).toUpperCase()}
                                    selected={cart.delivery?.channel === del}
                                    onClick={() => {
                                        cart['delivery'] = { channel: del };
                                        _update.data('cart', cart);
                                    }}
                                />
                                <div className={`side ${cart.delivery?.channel === del ? 'active' : ''} p-3 mt-3 primary`}>
                                    <div className="row mb-2">
                                        <div className="col-6">Delivery Time</div>
                                        <div className="col-6 text-right">{del === 'cr' ? '30mins' : '30 - 45mins'}</div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-6">Delivery Charge</div>
                                        <div className="col-6 text-right">₦{func.numberFormat(cartFx.deliveryFee(del))}</div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-6">Ratings</div>
                                        <div className="col-6 text-right">
                                            <Rate defaultValue={3} disabled />
                                        </div>
                                    </div> */}
                                </div>
                                {d < 3 && (
                                    <div className="divider mt-4" />
                                )}
                            </div>
                        ))}

                        <div>&nbsp;</div>
                        <Button
                            type="submit" variant={`${minCartValue ? 'secondary' : 'gray'} btn-block btn-md text-uppercases`} disabled={!cart.delivery}
                            onClick={() => {
                                if (minCartValue) {
                                    if (cart.delivery?.channel) {
                                        checkout();
                                    } else {
                                        presentAlert(`Please choose a delivery partner`);
                                    }
                                } else {
                                    presentAlert(`We require a minimum order of <b>₦${func.numberFormat(active_venue?.delivery.cart_minimum)}</b> to qualify for delivery.`);
                                }
                            }}
                            style={{ height: 49 }}
                        >
                            Continue to payment
                        </Button>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default Cart2;
