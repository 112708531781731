import React from 'react';
import { Button } from 'react-bootstrap';
import { images } from 'utils';


export default class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);

        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    close = () => {
        window.location = '/';
    }

    render() {
        if (this.state.hasError) {
            return (
                <React.Fragment>
                    <article id="Maintenance">
                        <p>
                            <img src={images.Logo} alt="Chicken republic" width="100px" />
                        </p>
                        <h1>OOPS!</h1>
                        <div>
                            <p>Something went wrong! Rest assured, our Engineers have been notified.</p>
                            {/* <p>&mdash; The Tomati Team</p> */}
                            <div>&nbsp;</div>
                            <Button variant="secondary btn-roundeds" onClick={this.close}>
                                &nbsp; &nbsp; OKAY &nbsp; &nbsp;
                            </Button>
                        </div>
                    </article>
                </React.Fragment>
            );
        }

        return this.props.children;
    }
}