import React from 'react';
import { useIonToast } from '@ionic/react';
import { _store } from '_Store';
import { Form, Input, Spin } from 'antd';
import { func, axius, types } from 'utils';
import { Verify, PasswordStrength } from 'components';
import { Button } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router';

interface StateProps {
    onSuccess?: () => void;
};
interface Props extends RouteComponentProps, types.StateProps, StateProps { };
const FormPassword: React.FC<Props> = props => {
    const { __auth: { user } } = _store.useState();

    const [form] = Form.useForm();
    const [presentToast] = useIonToast();

    const [vv, setValues] = React.useState({ password: '' });
    const [errMsg, setErrMsg] = React.useState('');
    const [verified, setVerified] = React.useState(false);
    const [strongPWD, setStrongPWD] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);

    React.useEffect(() => {
        if (func.getStorage('defaultPWD')) {
            setVerified(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submit = (v: any) => {
        if (strongPWD) {
            setErrMsg('');
            setSubmitting(true);
            axius.put(`users/${user.uuid}/passwords`, { ...v }).then(res => {
                setSubmitting(false);
                if (res.status === 200) {
                    props.onSuccess && props.onSuccess();
                    func.delStorage('defaultPWD');
                    presentToast(`Your password has been changed.`, 1500);
                } else {
                    setErrMsg(res.message);
                }
            });
        } else {
            setErrMsg('Please select a strong password');
        }
    }

    return (
        <React.Fragment>
            <Spin spinning={submitting} indicator={<i className="fa fa-spin fa-circle-notch" />}>
                {!verified && (
                    <Verify
                        {...props}
                        sendOtp={true}
                        v={{ phone_number: user.phone_number }}
                        onSuccess={() => {
                            setVerified(true);
                        }}
                    />
                )}

                {verified && (
                    <div>
                        <h4><b>Change password</b></h4>
                        <div>&nbsp;</div>

                        {errMsg && (<div className="alert alert-danger text-center">{errMsg}</div>)}

                        <Form id="Login" form={form} layout="vertical" onFinish={submit}>
                            <div className="full-input animate__animated animate__fadeIn">
                                <Form.Item label="Current password" name="oldPassword" rules={[{ required: true, message: 'Field is required' },]}>
                                    <Input type="password" id="u_npwd" size="large" onChange={e => setValues({ ...vv, password: e.target.value })} />
                                </Form.Item>
                                <i className={`input-icon text-muted fa fa-eye`} onClick={() => func.togglePasswordShow('u_npwd')} />
                            </div>

                            <div className="full-input animate__animated animate__fadeIn">
                                <Form.Item label="New password" name="newPassword" rules={[{ required: true, message: 'Field is required' },]}>
                                    <Input type="password" id="u_rpwd" size="large" onChange={e => setValues({ ...vv, password: e.target.value })} />
                                </Form.Item>
                                <i className={`input-icon text-muted fa fa-eye`} onClick={() => func.togglePasswordShow('u_rpwd')} />
                            </div>
                            <PasswordStrength {...props} value={vv.password} onChange={(e: boolean) => setStrongPWD(e)} />
                            <div>&nbsp;</div>

                            <Button type="submit" variant="secondary btn-block btn-md">
                                Save
                            </Button>
                        </Form>
                    </div>
                )}
            </Spin>
        </React.Fragment>
    );
};

export default FormPassword;