import React from 'react';
import { IonContent, IonPage, useIonAlert } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { Button, Alert } from 'react-bootstrap';
import { axius, func, types } from 'utils';
import { Header, IonLoading, TabsOffset } from 'components';
import { Form, Input, Rate } from 'antd';
import { _store } from '_Store';

interface Props extends RouteComponentProps, types.StateProps { };
const Review: React.FC<Props> = props => {

    const { __data: { order } } = _store.useState();

    const [form] = Form.useForm();
    const [presentAlert] = useIonAlert();

    const [step, setStep] = React.useState(1);
    const [rate, setRate] = React.useState({ meal_rate: 0, delivery_rate: 0 });
    const [errMsg, setErrMsg] = React.useState('');
    const [loading, setLoading] = React.useState('');

    const ord = order;

    const submit = (v: any) => {
        if (rate.meal_rate > 0 && ((ord.type === 'delivery' && rate.delivery_rate > 0) || ord.type === 'pickup')) {
            setErrMsg('');
            setLoading('submitting review...');
            axius.post(`orders/${ord.uuid}/reviews`, { ...rate, ...v }).then(res => {
                if (res.status === 200) {
                    if (rate.meal_rate <= 3 || (ord.type === 'delivery' && rate.delivery_rate <= 3)) {
                        props.history.push(`/help/${ord.id}/low`);
                    } else {
                        setStep(2);
                    }
                } else {
                    if (res.status === 412) {
                        setErrMsg(res.data.join('<br />'));
                    } else {
                        setErrMsg(res.message);
                    }
                }
                setLoading('');
            });
        } else {
            presentAlert(`You must at least choose a rating`);
        }
    }

    return (
        <IonPage id="Review">
            <Header {...props} showSearch={false} />

            <IonContent fullscreen>
                <IonLoading open={loading ? true : false} message={loading} onCancel={() => setLoading('')} />

                {step === 1 && (
                    <div className="p-4">
                        <Form form={form} layout="vertical" onFinish={submit} className="mt-4">
                            <h4 className="fw-600 mb-0s">Meal Review</h4>
                            <Rate
                                allowHalf={false}
                                className="box"
                                defaultValue={ord.review?.meal_rate}
                                disabled={ord.review?.id ? true : false}
                                onChange={meal_rate => setRate(sr => ({ ...sr, meal_rate }))}
                            />
                            <div className="small mt-1 text-muted">Click to rate</div>

                            <p>&nbsp;</p>

                            {ord.type === 'delivery' && (
                                <>
                                    <h4 className="fw-600 mb-0s">Delivery Service Review</h4>
                                    <Rate
                                        allowHalf={false}
                                        className="box"
                                        defaultValue={ord.review?.delivery_rate}
                                        disabled={ord.review?.id ? true : false}
                                        onChange={delivery_rate => setRate(sr => ({ ...sr, delivery_rate }))}
                                    />
                                    <div className="small mt-1 text-muted">Click to rate</div>
                                </>
                            )}

                            <p>&nbsp;</p>

                            {errMsg && (<Alert variant="danger" className="text-center">{errMsg}</Alert>)}

                            <Form.Item label="Comment" name="comment" initialValue={ord.review?.comment}>
                                <Input.TextArea size="large" rows={4} disabled={ord.review?.id ? true : false} />
                            </Form.Item>
                            <div>&nbsp;</div>

                            {!ord.review?.id && (
                                <Button type="submit" variant="secondary btn-block btn-md text-uppercases">
                                    Submit Review
                                </Button>
                            )}
                        </Form>
                    </div>
                )}

                {step === 2 && (
                    <div className="p-4 text-center d-flex justify-content-center align-items-center" style={{ height: '70vh', }}>
                        <div style={{ width: '90vw' }}>
                            <Rate defaultValue={3} disabled />
                            <div>&nbsp;</div>
                            <h4 className="fw-600 mb-0">Thank You <br /> For Your Feedback</h4>
                            <div>&nbsp;</div>
                            <div className="text-muted">Your feedback has been logged.</div>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <Button type="submit" variant="secondary btn-block btn-md text-uppercases" onClick={() => func.redirect('/user/orders')}>
                                Back to Home
                            </Button>
                        </div>
                    </div>
                )}
                <TabsOffset />
            </IonContent>
        </IonPage>
    );
};

export default Review;
