import React from 'react';
import { Form, Input, Spin } from 'antd';
import { RouteComponentProps } from 'react-router';
import { axius, types } from 'utils';
import { Verify } from 'components';
import { useIonToast } from '@ionic/react';
import { Button } from 'react-bootstrap';
import { _store, _update } from '_Store';

interface StateProps {
    onSuccess?: () => void;
};
interface Props extends RouteComponentProps, types.StateProps, StateProps { };
const FormEmail: React.FC<Props> = props => {
    const { __auth: { user } } = _store.useState();

    const [form] = Form.useForm();
    const [presentToast] = useIonToast();

    const [errMsg, setErrMsg] = React.useState('');
    const [verified, setVerified] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);

    React.useEffect(() => {
        form.setFieldsValue({
            email: user.email,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const findAccount = (v: any) => {
        setErrMsg('');
        setSubmitting(true);
        axius.get(`users/${v.email.toLowerCase()}`, {}).then(res => {
            setSubmitting(false);
            if (res.status === 200) {
                if (res.data.id !== user.id) {
                    setErrMsg('An account with this email already exists.');
                } else {
                    submit(v);
                }
            } else {
                submit(v);
            }
        });
    }

    const submit = (v: any) => {
        setErrMsg('');
        setSubmitting(true);
        axius.put(`users/${user.uuid}`, v).then(res => {
            setSubmitting(false);
            if (res.status === 200) {
                props.onSuccess && props.onSuccess();
                _update.auth('user', res.data);
                presentToast('You have changed your email address', 1500);
            } else {
                setErrMsg(res.message);
            }
        });
    }

    return (
        <React.Fragment>
            <Spin spinning={submitting} indicator={<i className="fa fa-spin fa-circle-notch" />}>
                {!verified && (
                    <Verify
                        {...props}
                        title="Verify to change email address"
                        sendOtp={true}
                        v={{ phone_number: user.phone_number }}
                        onSuccess={() => {
                            setVerified(true);
                        }}
                    />
                )}

                {verified && (
                    <div>
                        <h4><b>Change email address</b></h4>
                        <div>&nbsp;</div>

                        {errMsg && (<div className="alert alert-danger text-center">{errMsg}</div>)}

                        <Form form={form} layout="vertical" onFinish={findAccount}>
                            <Form.Item name="email" noStyle rules={[{ required: true, message: 'Email address is required' },]}>
                                <Input type="email" size="large" placeholder="" />
                            </Form.Item>
                            <div>&nbsp;</div>

                            <Button type="submit" variant="secondary btn-block btn-md">
                                Save
                            </Button>
                        </Form>
                    </div>
                )}
            </Spin>
        </React.Fragment>
    );
};

export default FormEmail;