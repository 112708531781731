import React from 'react';
import queryString from 'query-string';
import { IonContent, IonPage } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { Empty, Filter, Header, Item, TabsOffset } from 'components';
import { axius, func, types } from 'utils';
import _ from 'lodash';
import { _store } from '_Store';


interface Props extends RouteComponentProps, types.StateProps { };
const Search: React.FC<Props> = props => {

    const limit = 12;

    const { __data: { filter, location, active_venue, categories } } = _store.useState();

    const [step, setStep] = React.useState({ items: 0, venues: 0 });
    const [data, setData] = React.useState({ items: [], venues: [] });
    const [bigData, setBigData] = React.useState({ items: [], venues: [] });
    const [loading, setLoading] = React.useState(true);
    const [results, setResults] = React.useState({ total: 0, items: 0, venues: 0 });

    let qs = queryString.parse(props.history.location.search);

    React.useEffect(() => {
        getData();
        // window['$'](`#carousel-category-filters`).owlCarousel(owlCarouselOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.history.location.search, filter]);

    const getData = () => {
        setLoading(true);
        setResults({ total: 0, items: 0, venues: 0 });
        setData({ items: [], venues: [] });
        const categoryUUID = qs.category ? categories.find(ctg => ctg.id === +(qs.category || 0))?.uuid : '';
        axius.post('search', {
            tags: !qs.q ? (filter.tags || []) : [],
            range: 30,
            venues: [active_venue.uuid],
            keyword: qs.q || '',
            min_price: !qs.q ? _.isEmpty(filter.min_price) ? undefined : +((filter.min_price ? filter.min_price.toString() : '').replace(',', '')) : 0,
            max_price: !qs.q ? _.isEmpty(filter.max_price) ? undefined : +((filter.max_price ? filter.max_price.toString() : '').replace(',', '')) : 9999999999999999,
            delivery_options: [location.option],
            latitude: location.address.lat,
            longitude: location.address.lng,
            results_type: ['items'],
            limit: 999999,
            venue_categories: categoryUUID ? [categoryUUID] : [],
        }).then(res => {
            if (res.status === 200) {
                const q = ((qs.q as string) || '').toLowerCase();
                const items = [
                    ...res.items.filter((i: types.Item) => i.name.toLowerCase().includes(q)),
                    ...res.items.filter((i: types.Item) => !i.name.toLowerCase().includes(q)),
                ];
                var cData = {
                    items: func.chunk(
                        items.map((item: types.Item) => {
                            return {
                                ...item,
                                distance: +func.distanceMatrix(
                                    { lat: location?.address?.lat, lng: location?.address?.lng },
                                    { lat: active_venue.address.lat, lng: active_venue.address.lng },
                                    true
                                ),
                            };
                        })
                            // .sort((a: types.Item, b: types.Item) => (a.distance > b.distance) ? 1 : -1)
                            .filter((item: types.Item) => item.status === 'published')
                            .filter((item: types.Item) => location.option === 'delivery' ? item.can_deliver && item.venue_categories[0]?.delivery : item),
                        limit),
                    venues: [],
                };
                setBigData(cData);
                setData({
                    items: cData.items[0] || [],
                    venues: cData.venues[0] || [],
                });
                setResults({
                    total: (cData.items.flat()).length + (cData.venues.flat()).length,
                    items: (cData.items.flat()).length,
                    venues: (cData.venues.flat()).length,
                });
            }
            setLoading(false);
        });
    }

    const getNext = (key: string) => {
        const newStep = JSON.parse(JSON.stringify(step));
        const newData = JSON.parse(JSON.stringify(data));
        const newBigData = JSON.parse(JSON.stringify(bigData));

        setData({ ...data, [key]: newData[key].concat(newBigData[key][newStep[key] + 1] || []), });

        newStep[key] = newStep[key] + 1;
        setStep(newStep);
    }

    return (
        <IonPage id="Search">
            <Header {...props} showSearch={true} showSegments={false} />
            <IonContent fullscreen>
                <div id="SearchScreen" className="content">
                    <Filter {...props} categories={[]} />

                    <div className="mt-0 ml-4 mr-4">
                        {loading && func.generateOptions(limit).map(i => (
                            <div key={i} className="skeleton my-4" style={{ borderRadius: 7, height: 108 }} />
                        ))}

                        {!loading && results.items === 0 && (
                            <Empty
                                style={{}}
                                text={
                                    <div>
                                        No results for "<b>{qs.q}</b>" here,<br /> try something else.
                                    </div>
                                }
                            />
                        )}

                        {!loading && results.total > 0 && (
                            <div>
                                <div>&nbsp;</div>
                                {!loading && (
                                    <div className="fs-12 fw-500s mb-4 text-muted">
                                        {results.total > 0 ? func.numberFormat(results.total) : 'No'} results found for "{qs.q}"
                                    </div>
                                )}

                                {!loading && data.items.length > 0 && data.items.map((item: types.Item) => (
                                    item.status === 'published' && (
                                        <div key={item.id} className="mb-4">
                                            <Item {...props} item={item} layout="list" description={true} />
                                        </div>
                                    )
                                ))}

                                {results.items > data.items.length && (
                                    <div className="text-center pointer small" onClick={() => getNext('items')}>load more</div>
                                )}
                                <p>&nbsp;</p>
                            </div>
                        )}
                    </div>

                </div>
                <TabsOffset />
            </IonContent>
        </IonPage>
    );
};

export default Search;
