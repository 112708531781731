import React from 'react';
import { Form, Input, Select, Spin } from 'antd';
import moment from 'moment';
import { RouteComponentProps } from 'react-router';
import { useIonToast } from '@ionic/react';
import { axius, types } from 'utils';
import { DatePicker } from 'components';
import { Button } from 'react-bootstrap';
import { _store, _update } from '_Store';

interface StateProps {
    onSuccess?: () => void;
};
interface Props extends RouteComponentProps, types.StateProps, StateProps { };
const FormProfile: React.FC<Props> = props => {

    const { __auth: { user } } = _store.useState();

    const [form] = Form.useForm();
    const [presentToast] = useIonToast();

    const [errMsg, setErrMsg] = React.useState('');
    const [submitting, setSubmitting] = React.useState(false);

    React.useEffect(() => {
        form.setFieldsValue({
            ...user,
            birthdate: user.birthdate === '1870-01-01' ? '' : user.birthdate,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submit = (v: any) => {
        setErrMsg('');
        setSubmitting(true);
        v['birthdate'] = moment(v.birthdate).format('YYYY-MM-DD');
        axius.put(`users/${user.uuid}`, v).then(res => {
            setSubmitting(false);
            if (res.status === 200) {
                props.onSuccess && props.onSuccess();
                _update.auth('user', res.data);
                presentToast('You have changed your profile details', 1500);
            } else {
                setErrMsg(res.message);
            }
        });
    }

    return (
        <React.Fragment>
            <Spin spinning={submitting} indicator={<i className="fa fa-spin fa-circle-notch" />}>
                <h4><b>Edit profile</b></h4>
                <div>&nbsp;</div>

                {errMsg && (<div className="alert alert-danger text-center">{errMsg}</div>)}

                <Form form={form} layout="vertical" onFinish={submit}>
                    <Form.Item label="First name" name="first_name" rules={[{ required: true, message: 'Field is required' },]}>
                        <Input type="text" size="large" />
                    </Form.Item>
                    <Form.Item label="Last name" name="last_name" rules={[{ required: true, message: 'Field is required' },]}>
                        <Input type="text" size="large" />
                    </Form.Item>
                    <Form.Item label="Gender" name="gender" rules={[{ required: true, message: 'Field is required' },]}>
                        <Select size="large" placeholder="Choose a gender">
                            <Select.Option value="male">Male</Select.Option>
                            <Select.Option value="female">Female</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Date of birth" name="birthdate" rules={[{ required: true, message: 'Field is required' },]}>
                        <DatePicker form={form} value={user.birthdate} onChange={(birthdate: string) => { form.setFieldsValue({ birthdate }); }} />
                    </Form.Item>
                    {/* <div>&nbsp;</div> */}

                    <Button type="submit" variant="secondary btn-block btn-md">
                        Save
                    </Button>
                </Form>
            </Spin>
        </React.Fragment>
    );
};

export default FormProfile;