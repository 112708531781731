import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { func, images, types } from 'utils';
import { RouteComponentProps, useParams } from 'react-router';
import { _store, _update } from '_Store';

// const jQuery = (window as any).jQuery;

interface Params { id: string };
interface StateProps {
    categories?: any;
    showFilter?: boolean;
    onCategoryChange?: any;
};
interface Props extends RouteComponentProps, types.StateProps, StateProps { };

const Filter: React.FC<Props> = (props) => {

    const params: Params = useParams();

    const { __data: { filter, categories } } = _store.useState();

    // const [filtered, setFiltered] = React.useState(0);
    // const [showFilter, setShowFilter] = React.useState(false);

    React.useEffect(() => {
        var filtereD = 0;
        Object.keys(filter).forEach((of) => {
            if (['categoriess', 'tags', 'drinks', 'cuisines', 'tags', 'venues'].includes(of)) {
                if (filter[of].length > 0) {
                    filtereD = filtereD + filter[of].length;
                    console.log(`++ ${of}`);
                }
            }
            if (of === 'min_price' && +filter[of] > 0) {
                filtereD++;
                console.log('++ min_price');
            }
            if (of === 'max_price' && filter[of] && +(filter[of].toString().replace(',', '')) > 0) {
                filtereD++;
                console.log('++ max_price', filter[of]);
            }
        });
        // setFiltered(filtereD);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    React.useEffect(() => {
        // var headerHeight = document.getElementById('Header')?.offsetHeight as number;
        // var filterElement = document.getElementById('Filter') as HTMLElement;
        // filterElement.classList.remove('sticky');
        // let scrollPosition = Math.round(window.scrollY); console.log(scrollPosition)
        // window.addEventListener('scroll', () => {
        //     let scrollPosition = Math.round(window.scrollY);
        //     if (scrollPosition > (headerHeight - 150)) {
        //         filterElement.classList.add('sticky');
        //         jQuery(filterElement).css({ top: headerHeight - 2 });
        //     } else {
        //         filterElement.classList.remove('sticky');
        //     }
        // });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const activeCategory = (props.categories || categories || []).find((ctg: types.Category) => ctg.id === +params.id);

    return (
        <React.Fragment>
            <div id="Filter" className="d-flex py-2 px-3 justify-content-center justify-content-between stickys">
                <div>
                    {(props.categories || categories || []).length > 0 && (
                        <div>
                            <Dropdown>
                                <Dropdown.Toggle variant="clear" id="">
                                    <div className="btn btn-primary tt-none" style={{ height: 'auto' }}>
                                        <small className="fw-600">
                                            {activeCategory ? activeCategory.name.substring(0, 25) : 'Select a category'}
                                        </small>&nbsp;
                                        <img src={images.ArrowDown} alt="ArrowDown" />
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="animate__animated animate__fadeIn animate__faster" style={{ height: '43vh', overflow: 'scroll' }}>
                                    {(props.categories || categories || []).map((ctg: types.Category) => (
                                        <Dropdown.Item key={ctg.uuid} onClick={() => {
                                            if (props.onCategoryChange) {
                                                props.onCategoryChange(ctg.uuid);
                                            } else {
                                                _update.data('filter', { categories: [ctg.uuid] });
                                                props.history.push(`/c/${func.seourl(ctg.name)}.${ctg.id}`);
                                            }
                                        }}>{ctg.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    )}
                </div>
                {/* <div>
                    <Button variant="primary-outline tt-none" onClick={() => setShowFilter(true)}>
                        <img src={FilterSVG} alt="Filter" /> &nbsp; Filter {filtered > 0 && (<div className="budge">{helper.numberFormat(filtered)}</div>)}
                    </Button>
                </div> */}
            </div>
            {/* <div className="divider" /> */}

            {/* {showFilter && (
                <BottomModal
                    title=""
                    onClose={() => {
                        setShowFilter(false);
                    }}
                    style={{ height: '100dvh', position: 'fixed', backgroundColor: '#fff' }}
                >
                    <FilterScreen
                        {...props}
                        style={{ minHeight: '92vh' }}
                        onClose={() => {
                            setShowFilter(false);
                        }}
                        onApply={(filters) => {
                            setShowFilter(false);
                            if (props.history.location.pathname.includes('/v/') && props.onApply) {
                                props.onApply(filters);
                            } else {
                                if (props.history.location.pathname.includes('/search')) {

                                } else {
                                    props.history.push('/search');
                                }
                            }
                        }}
                        onChange={(filters) => {

                        }}
                    />
                </BottomModal>
            )} */}
        </React.Fragment >
    );
};

export default Filter;
