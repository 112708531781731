import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Form, Input, Select } from 'antd';
import libphonenumber from 'google-libphonenumber';
import { func } from 'utils';
import { _store } from '_Store';

interface StateProps {
    // state
    _auth?: any;
    _data?: any;

    form: any;
    value?: string;
    onChange?: any;
};

interface Props extends RouteComponentProps, StateProps { };

const PhoneNumber: React.FC<Props> = props => {
    const { form } = props;
    const { __data: { location, countries }, } = _store.useState();

    React.useEffect(() => {
        const user = func.getStorageJson('user') || { country: { code: 'ng' } };
        const countryCode = location.address?.iso?.toLowerCase() || 'ng';
        form.setFieldsValue({
            country: countryCode,
            phone_number: user.phone_number && `${user.phone_number.replace('+' + user.country.calling_code, '0')}`,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const validateMobile = (phone: string) => {
        try {
            const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
            return phoneUtil.isPossibleNumber(phoneUtil.parse(phone, (form.getFieldValue('country') || 'ng')));
        } catch (error) {
            return false;
        }
    }

    const checkNumber = (rule: any, value: string, callback: any) => {
        let isValid = validateMobile(value);
        if (!value) {
            callback();
        }

        let max = countries.find((cnt: any) => cnt.code === (form.getFieldValue('country') || 'ng'))?.phone_length || 0;
        if (value && value.length > max) {
            callback(`Can not be more than ${max} numbers`);
        }

        if (!isValid) {
            callback(`Phone number is not valid`);
        }

        callback();
    }

    return (
        <React.Fragment>
            {countries.length > 1 ? (
                <Form.Item label={<span className="required">Phone Number</span>}>
                    <Input.Group compact>
                        <Form.Item name="country" noStyle rules={[{ required: true, message: 'Country is required' }]}>
                            <Select style={{ width: '28vw' }} size="large" disabled={countries.length === 1 ? true : false}>
                                {countries.map((loc: any) => (
                                    <Select.Option key={loc.code} value={loc.code}>
                                        <small>
                                            <img
                                                src={`https://flagcdn.com/16x12/${loc.code.toLowerCase()}.png`}
                                                alt={loc.name}
                                                onError={(e: any) => e.target.src = `https://www.countryflags.io/${loc.code.toLowerCase()}/flat/16.png`}
                                            /> &nbsp;
                                            +{loc.calling_code}
                                        </small>
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="phone_number" noStyle rules={[
                            { required: true, message: 'Phone number is required' },
                            { validator: checkNumber, }
                        ]}>
                            <Input style={{ width: 'calc(100% - 28vw)' }} type="number" size="large" placeholder="07000000000" />
                        </Form.Item>
                    </Input.Group>
                </Form.Item>
            ) : (
                <div>
                    <Form.Item name="country" className="hide">
                        <Input type="country" size="large" />
                    </Form.Item>
                    <Form.Item name="phone_number">
                        <Input prefix="+234" type="number" size="large" placeholder="07000000000" />
                    </Form.Item>
                </div>
            )}
        </React.Fragment>
    );
}

export default PhoneNumber;