export const geocode = async (lat: number, lng: number) => {
    try {
        const geocode = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCDvNRJ4mkaI0OxeidE9k_MhkjYVlQBfQo`);
        const res = await geocode.json();
        return { status: 200, data: res };
    } catch (error) {
        return { status: 606, message: 'Network request failed', error };
    }
}

export const addressComponents = (plc: any) => {
    return {
        lat: typeof plc.geometry?.location?.lat === 'number' ? plc.geometry?.location?.lat : plc.geometry?.location?.lat(),
        lng: typeof plc.geometry?.location?.lng === 'number' ? plc.geometry?.location?.lng : plc.geometry?.location?.lng(),
        iso: ((plc.address_components || []).find((pa: any) => (pa.types || []).includes('country')) || { short_name: '' }).short_name,
        city: ((plc.address_components || []).find((pa: any) => (pa.types || []).includes('locality')) || { long_name: '' }).long_name,
        name: `${plc.name ? `${plc.name}, ` : ''}${plc.formatted_address.replace(`${plc.name}, `, ``)}`,
        state: ((plc.address_components || []).find((pa: any) => (pa.types || []).includes('administrative_area_level_1')) || { long_name: '' }).long_name,
        street: ((plc.address_components || []).find((pa: any) => (pa.types || []).includes('route')) || {}).long_name || '',
        country: ((plc.address_components || []).find((pa: any) => (pa.types || []).includes('country')) || { long_name: '' }).long_name,
        house_no: ((plc.address_components || []).find((pa: any) => (pa.types || []).includes('street_number')) || {}).long_name || '',
    };
}

export const myCurrentLocation = () => {
    return new Promise((resolve) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
                const pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                geocode(pos.lat, pos.lng).then(geoc => {
                    resolve(addressComponents(geoc.data.results[0]));
                });
            }, (e) => {
                console.log(e);
            });
        } else {
            resolve(null);
        }
    });
}