import React from 'react';
import { Form, Input, Spin } from 'antd';
import { Button } from 'react-bootstrap';
import { axius } from 'utils';


const Verify = props => {
    const { showScreen, sendOtp } = props;

    const [form] = Form.useForm();
    const [count, setCount] = React.useState(0);
    const [errMsg, setErrMsg] = React.useState('');
    const [consent, setConsent] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);

    React.useEffect(() => {
        if (sendOtp) {
            setConsent(true);
        } else {
            startTimer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const startTimer = () => {
        const timer = setInterval(() => {
            setCount(prevCount => prevCount + 1);
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }

    const sendWhatsApp = async () => {
        setErrMsg('');
        setSubmitting(true);
        axius.post('otp', { to: props.v.phone_number, channel: 'phone' }).then((res) => {
            setSubmitting(false);
            if (res.status === 200) {
                setCount(0);
                startTimer();
                setConsent(false);
            } else {
                setErrMsg(res.message || 'Failed to send OTP. Please check your number and retry');
            }
        });
    }

    const submit = (v) => {
        if (typeof props.verify === 'undefined' || props.verify === true) {
            setErrMsg('');
            setSubmitting(true);
            axius.put('otp', { ...v, to: props.v.phone_number, channel: 'phone' }).then(res => {
                setSubmitting(false);
                if (res.status === 200) {
                    props.onSuccess ? props.onSuccess(v) : showScreen('signup');
                } else {
                    setErrMsg(res.message || 'Verification failed.');
                }
            });
        } else {
            props.onSuccess ? props.onSuccess(v) : showScreen('signup');
        }
    }

    return (
        <React.Fragment>
            <Spin spinning={submitting} indicator={<span><i className="fa fa-spin fa-circle-notch" /></span>}>
                {consent && (
                    <div>
                        <h4 className="primary"><b>{props.title || 'Verify your account'}</b></h4>
                        {errMsg && (<div className="alert alert-danger text-center">{errMsg}</div>)}
                        <div className="text-muted">To proceed, we need to verify you.</div>
                        <div className="text-muted">One Time Passcode (OTP) will be sent to {props.v.phone_number}.</div>
                        <p>&nbsp;</p>
                        <Button type="submit" variant="secondary btn-block btn-md" onClick={sendWhatsApp}>
                            Send OTP
                        </Button>
                    </div>
                )}

                {!consent && (
                    <div>
                        <h4><b>{props.title || 'Verify phone number'}</b></h4>
                        <div className="text-muted">
                            Enter the code sent to {props.v.phone_number}.
                            {props.onChangeNumber && (
                                <div onClick={() => props.onChangeNumber()}>Wrong number? <span className="secondary">Change here</span></div>
                            )}
                        </div>
                        <div>&nbsp;</div>

                        {errMsg && (<div className="alert alert-danger text-center">{errMsg}</div>)}

                        <Form form={form} layout="vertical" onFinish={submit}>
                            <Form.Item name="code" rules={[{ required: true, message: 'Code is required' },]}>
                                <Input type="number" size="large" className="text-center" placeholder="• • • • • •" style={{ letterSpacing: 10 }} />
                            </Form.Item>
                            <div>&nbsp;</div>
                            
                            <Button type="submit" variant="secondary btn-block btn-md">
                                Verify &amp; Continue
                            </Button>
                            <div>&nbsp;</div>

                            <div className="text-center">
                                Didn't get the code? {count < 60 ? `Resend in ${60 - count} secs` : <b onClick={sendWhatsApp}>RESEND NOW</b>}.
                            </div>
                        </Form>
                    </div>
                )}
            </Spin>
        </React.Fragment>
    );
};

export default Verify;