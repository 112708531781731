import React from 'react';
import { RouteComponentProps } from 'react-router';
import { images } from 'utils';

interface StateProps {
    title?: string;
    onClick?: any;
    floating?: boolean;
};
interface Props extends RouteComponentProps, StateProps { };
const GoBack: React.FC<Props> = (props) => {
    const { onClick, history, floating, title } = props;

    return (
        <React.Fragment>
            <div id="GoBack" className={`${floating ? 'floating' : ''} d-flex align-items-center`} onClick={() => {
                onClick ? onClick() : history.goBack();
            }}>
                {!floating && (
                    <span className="pointer">
                        <img src={images.ArrowLeft} alt="ArrowLeft" className="img-fluid" style={{ height: 22 }} />
                    </span>
                )}

                {floating && (
                    <span className="pointer">
                        <img src={images.ArrowLeftCircle} alt="ArrowLeftCircle" className="img-fluid" />
                    </span>
                )}

                {title && (<div className="primary ml-3" dangerouslySetInnerHTML={{ __html: title }} />)}
            </div>
        </React.Fragment>
    );
}

export default GoBack;
