import React from 'react';
import { IonContent, IonPage, useIonAlert, useIonToast } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { Header, IonLoading, TabsOffset } from 'components';
import { axius, cartFx, func, images, types } from 'utils';
import { Alert, Button, ButtonGroup } from 'react-bootstrap';
import moment from 'moment';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { _store, _update } from '_Store';

import Cart2 from './Cart2';
import Checkout from './Checkout';

interface Props extends RouteComponentProps, types.StateProps { }
const Cart: React.FC<Props> = (props) => {

  const history = props.history;

  const [presentAlert] = useIonAlert();
  const [presentToast] = useIonToast();

  const { __auth: { user }, __data: { cart, location, cartDefault, options, active_venue } } = _store.useState();

  const [step, setStep] = React.useState(1);
  const [loading, setLoading] = React.useState('');

  // const eta = cart.items.find((ci: types.Item) => moment.duration(ci.preparation_time).asMinutes() === Math.max(...cart.items.map((ci: types.Item) => { return moment.duration(ci.preparation_time).asMinutes(); })));
  const deliveryDistance = +func.distanceMatrix(
    { lat: location?.address?.lat, lng: location?.address?.lng },
    { lat: active_venue?.address?.latitude, lng: active_venue?.address?.longitude, },
    true
  );
  const minCartValue = location.option !== 'delivery' || cartFx.total(cart.items) >= +(active_venue?.delivery?.cart_minimum || 0);

  React.useEffect(() => {
    active_venue?.uuid && getVenue();
    window.scroll(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    return () => {
      if (!props.history.location.pathname.includes('/cart')) {
        setStep(1);
        setLoading('');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.history.location.pathname]);

  React.useEffect(() => {
    if (history.location.pathname !== '/cart') {
      setStep(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  React.useEffect(() => {
    active_venue?.uuid && getVenue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getVenue = (onOk: any = null) => {
    axius.get(`venues/${active_venue?.uuid}/items`).then((res) => {
      let o = JSON.parse(JSON.stringify(res));
      if (onOk) {
        onOk(o.data, o.venue);
      } else {
        const kart = func.getStorageJson('cart');
        _update.data('cart', { ...kart, venue: res.venue });
      }
    });
  }

  const updateCart = (item: types.Item, increment: '+' | '-') => {
    const doUpdate = () => {
      _update.data('cart', { ...cart, items: cartFx.add(item, cart.items, increment) });
    };
    if (item.quantity === 1 && increment === '-') {
      presentAlert({
        header: `Remove`,
        message: `Do you want to remove this product from your cart?`,
        buttons: [
          { text: `Cancel` },
          {
            text: `Remove`,
            handler: () => {
              _update.data('cart', { ...cart, items: cartFx.remove(item, cart.items), venue: cart.items.length === 0 ? {} : active_venue });
            },
          },
        ],
      });
    } else {
      doUpdate();
    }
  }

  const findVariants = (codes: string[]) => {
    return cart.items.map((item) => {
      return Object.keys(item.variants).map((v) => {
        return (item.variants as any)[v].map((iv: any) => {
          return iv;
        })
      }).flat();
    }).flat().filter((as) => codes.includes(as.code)).map((as) => as.option).join(', ');
  }

  const checkout = (deliveryFee: number) => {
    if ((location.option === 'delivery' && minCartValue) || location.option === 'pickup') {
      window.scroll(0, 0);
      if (step === 3) {
        if (user.uuid) {
          if (user.email) {
            if (cart.payType) {
              presentAlert({
                header: `Checkout`,
                message: `Are you ready to complete your order?`,
                buttons: [
                  { text: `Cancel` },
                  {
                    text: `Place Order`,
                    handler: () => {
                      setLoading(`placing order...`);
                      getVenue((items: types.Item[], venue: types.Venue) => {
                        _update.data('active_venue', venue);
                        if (venue.preferences.can_take_orders && venue.preferences.online) {
                          const refreshedItems = items.filter((men) => cart.items.map((ci: types.Item) => ci.uuid).includes(men.uuid)) || [];
                          const cantDeliver = refreshedItems.filter((ri) => !(ri.venue_categories[0] || {}).delivery || !ri.can_deliver) || [];
                          const notPublished = refreshedItems.filter((ri) => ri.status === 'draft') || [];
                          const notAvailable = refreshedItems.filter((ri) => !(venue.preferences.items_available || []).includes(ri.code)) || [];
                          if ((cantDeliver.length === 0 || location.option === 'pickup') && notPublished.length === 0 && notAvailable.length === 0) {
                            const allSides = cart.items.map((item: types.Item) => {
                              return Object.keys(item.variants).map((v) => {
                                return (item.variants as any)[v].map((iv: any) => {
                                  return iv.code;
                                }).flat()
                              }).flat();
                            }).flat().filter((as: string) => as);
                            const availableSides = venue.preferences.sides_available || [];
                            const sidesUnavailables = availableSides.length > 0 ? allSides.filter((as: string) => !availableSides.includes(as)) : [];
                            if (sidesUnavailables.length === 0) {
                              const order = {
                                type: location.option,
                                items: cart.items,
                                user: user.uuid,
                                venue: active_venue?.uuid,
                                address: {
                                  ...location.address,
                                  lat: location.address.lat.toString(),
                                  lng: location.address.lng.toString(),
                                },
                                status: cart.payType === 'advance' ? 12 : 0,
                                paytype: cart.payType,
                                delivery: cart.delivery,
                                amount: {
                                  total: cartFx.total(cart.items) + (location.option === 'delivery' ? deliveryFee : 0),
                                  amount: cartFx.total(cart.items),
                                  delivery: location.option === 'delivery' ? deliveryFee : 0,
                                },
                              };
                              axius.post('orders', order).then(async (res: types.AxiusResponse) => {
                                if (res.status === 200) {
                                  if (cart.payType === 'advance') {
                                    if (res.status === 200) {
                                      if (func.isMobile) {
                                        const browser = InAppBrowser.create(res.pay.checkout_url);
                                        browser.on('loadstop').subscribe((event) => {
                                          if (event.url.includes('/webhook/')) {
                                            browser.close();
                                            props.history.replace(`/webhook/${event.url.replace('https://', '').split('/')[2]}`);
                                            window.location.reload();
                                          }
                                        });
                                      } else {
                                        window.location = res.pay.checkout_url;
                                      }
                                    } else {
                                      presentAlert(res.message);
                                    }
                                  } else {
                                    setStep(1);
                                    _update.data('cart', cartDefault);
                                    _update.data('order', res.data);
                                    presentToast('Your order has been placed!', 1500);
                                    setTimeout(() => {
                                      props.history.replace(`/user/orders/${res.data.id}`);
                                      // window.location.reload();
                                    }, 100);
                                  }
                                } else {
                                  setLoading('');
                                  presentAlert(res.message);
                                }
                              });
                            } else {
                              setLoading('');
                              presentAlert(`We're sorry. We run out of the following item(s): ${findVariants(sidesUnavailables)}`);
                            }
                          } else {
                            setLoading('');
                            presentAlert(`
                            We're sorry. The following item(s) are not available for 
                            ${location.option}: ${[...cantDeliver, ...notPublished, ...notAvailable].map((cd) => { return cd.name; }).join(", ")}
                          `);
                          }
                        } else {
                          setLoading('');
                          presentAlert(`We're sorry. We are not taking any orders at this moment`);
                        }
                      });
                    },
                  },
                ],
              });
            } else {
              presentAlert(`Please choose a payment option`);
            }
          } else {
            emailPrompt();
          }
        } else {
          authPrompt();
        }
      } else {
        continueCarting();
      }
    } else {
      presentAlert(`We require a minimum order of <b>₦${func.numberFormat(active_venue?.delivery.cart_minimum)}</b> to qualify for delivery.`);
    }
  }

  const authPrompt = () => {
    presentAlert({
      header: `Log in or sign up!`,
      message: `Login in to complete your order`,
      buttons: [
        { text: `Cancel` },
        {
          text: `Continue`,
          handler: () => {
            props.history.push('/login');
          },
        },
      ],
    });
  }

  const emailPrompt = () => {
    presentAlert({
      header: `Please complete your profile!`,
      message: `Login in to complete your order`,
      buttons: [
        { text: `Cancel` },
        {
          text: `Set my email`,
          handler: () => {
            func.setStorageJson('edit_user', {
              from: props.location.pathname,
              edit: ['email'],
              option: 'promos',
            });
            props.history.push('/user/account');
          },
        },
      ],
    });
  }

  const continueCarting = () => {
    if (location.option === 'delivery') {
      setLoading('calculating delivery fee...');
      axius.post('orders/delivery-fee', {
        city: location.address.city.toLowerCase(),
        venue: active_venue.uuid,
        amount: cartFx.total(cart.items),
        source: {
          lat: active_venue.address.lat,
          lng: active_venue.address.lng,
        },
        destination: {
          lat: location.address.lat,
          lng: location.address.lng,
        },
      }).then(res => {
        if (res.data.channel) {
          _update.data('cart', { ...cart, delivery: { ...cart.delivery, channel: res.data.channel }, deliveryFee: res.data.amount });
          setStep(3);
        } else {
          presentAlert(`We do not deliver to ${location.address.city}. Please change your location.`);
        }
        setLoading('');
      });
    } else {
      setStep(3);
      // setStep((s) => s + 1);
    }
  }

  const deliveryTime = {
    from: active_venue.delivery?.schedule?.from || '09:00',
    to: active_venue.delivery?.schedule?.to || '20:00',
  }

  return (
    <IonPage id="Cart">
      <Header
        {...props}
        showSearch={false}
        showSegments={false}
        showBack={step > 1}
        goBack={() => {
          if (step === 1) {
            props.history.goBack();
          } else {
            setStep(1);
            // step === 3 && location.option === 'delivery' && Object.keys(delivery_partners).length === 1
            // if (location.option === 'delivery') {
            //   setStep(1);
            // } else {
            //   setStep((s) => s - 1);
            // }
          }
        }}
      />
      <IonContent fullscreen>
        <IonLoading
          open={loading ? true : false}
          message={loading}
          onCancel={() => setLoading('')}
        />

        {step === 1 && (
          <div id="Cart" className="content c-small">
            {cart.items.length > 0 && (
              <div>
                <div>&nbsp;</div>
                <div id="Segments" className="mb-3 d-flex justify-content-center">
                  <ButtonGroup>
                    {options.map(seg => (
                      <Button
                        key={seg.key}
                        variant={location.option === seg.key ? 'active' : ''}
                        onClick={() => _update.data('location', { option: seg.key })}
                      >
                        {seg.label}
                      </Button>
                    ))}
                  </ButtonGroup>
                </div>
              </div>
            )}
            {location.option === 'delivery' && !moment().isBetween(func.dates.dt + ' ' + deliveryTime.from, func.dates.dt + ' ' + deliveryTime.to) && (
              <Alert variant="warning" className="small text-xs">Delivery is currently not available</Alert>
            )}
            <div className="divider" />

            {cart.items.length > 0 && (
              <>
                <div className="p-4">
                  <h5 className="m-0 fw-600 primary text-uppercases">Cart</h5>
                  {!minCartValue && (
                    <div className="text-center mt-3">
                      <Alert variant="danger">
                        We require a minimum order of{" "}
                        <b>₦{func.numberFormat(active_venue?.delivery.cart_minimum)}</b>{" "}to qualify for delivery.
                        <div>
                          <div
                            className="pointer ins fw-500 mt-2"
                            onClick={() => _update.data('location', { option: 'pickup' })}
                          >
                            Change to pickup
                          </div>
                        </div>
                      </Alert>
                    </div>
                  )}
                </div>
                <div className="divider" />
              </>
            )}

            <div className="p-4">
              {cart.items.length === 0 && (
                <div
                  id="Loading"
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "63vh" }}
                >
                  <div className="text-center">
                    <img src={images.CartEmpty} alt="CartEmpty" height="90" />
                    <div className="mt-2" style={{ fontSize: 20 }}>Your cart is empty!</div>
                  </div>
                </div>
              )}

              {cart.items.length > 0 && (
                <div>
                  {(cart.items || []).map((item: any, i: number) => {
                    let price_total = item.price_discount || item.price;
                    for (let i = 0; i < Object.keys(item.variants).length; i++) {
                      const op = Object.keys(item.variants)[i];
                      for (let j = 0; j < (item.variants[op] || []).length; j++) {
                        const iop = item.variants[op][j];
                        if (iop.price > 0) {
                          price_total = price_total + (+iop.price * +iop.quantity);
                        }
                      }
                    }
                    price_total = price_total * (item.quantity || 1);
                    return (
                      <div key={item.uuid} className="row">
                        <div className="col-4">
                          <div
                            className="bg-img"
                            style={{
                              backgroundImage: `url("${(item.images_links || [])[0] || images.Logo}")`,
                              height: "12vh",
                              borderRadius: 4,
                            }}
                          />
                        </div>
                        <div className="col-8">
                          <div className="row">
                            <div className="col-8 primary fw-600">{item.name}</div>
                            <div className="col-4 smalls text-right">₦{func.numberFormat(price_total)}</div>
                          </div>
                          {Object.keys(item.variants).map((op) => (
                            <div key={op}>
                              {item.variants[op].map((po: any) => (
                                <div key={po.option} className="small text-muted">
                                  {po.quantity > 1 ? `${po.quantity} x ` : ''} {po.option} {po.price > 0 ? `(₦${func.numberFormat(+po.price * +po.quantity)})` : ''}
                                </div>
                              ))}
                            </div>
                          ))}
                          <div className="row">
                            <div className="col-7">&nbsp;</div>
                            <div className="col-5">
                              <div className="d-flex justify-content-between align-items-center fs-12 mt-2">
                                <div>
                                  <div
                                    className="bgc-danger fw-600 rounded"
                                    style={{ padding: "3px 10px" }}
                                    onClick={() => updateCart(item, "-")}
                                  >
                                    -
                                  </div>
                                </div>
                                <div className="px-2 fw-600s" style={{ fontSize: 20 }}>
                                  {func.numberFormat(item.quantity || 1)}
                                </div>
                                <div>
                                  <div
                                    className="bgc-warning fw-600 rounded"
                                    style={{ padding: "3px 10px" }}
                                    onClick={() => updateCart(item, "+")}
                                  >
                                    +
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 my-3 divider" />
                      </div>
                    );
                  })}

                  <div className="footers pb-3 mt-5">
                    <Button
                      variant={`${minCartValue ? 'secondary' : 'gray'} btn-block btn-lg text-uppercases`}
                      onClick={() => checkout(deliveryDistance)}
                    >
                      <div className="d-flex justify-content-between">
                        <div>Checkout</div>
                        <div>
                          <b>₦{func.numberFormat(cartFx.total(cart.items))}</b>
                        </div>
                      </div>
                    </Button>
                  </div>

                  <div className="text-center my-2">
                    <span className="primary fw-600 ins" onClick={() => props.history.push(`/`)}>
                      Continue Shopping
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )
        }

        {
          step === 2 && (
            <Cart2 {...props} checkout={checkout} minCartValue={minCartValue} />
          )
        }

        {
          step === 3 && (
            <Checkout
              {...props}
              checkout={checkout}
              minCartValue={minCartValue}
            />
          )
        }
        <TabsOffset />
      </IonContent >
    </IonPage >
  );
};

export default Cart;
