import React from 'react';
import { useIonToast } from '@ionic/react';
import { Button } from 'react-bootstrap';
import { Form, Input, Select, Spin } from 'antd';
import { RouteComponentProps } from 'react-router';
import { axius,types } from 'utils';
import { Verify } from 'components';
import { _store, _update } from '_Store';

interface StateProps {
    onSuccess?: any;
};
interface Props extends RouteComponentProps, types.StateProps, StateProps { };

const FormPhone: React.FC<Props> = props => {

    const { __auth: { user }, __data: { countries } } = _store.useState();

    const [form] = Form.useForm();
    const [presentToast] = useIonToast();

    const [errMsg, setErrMsg] = React.useState('');
    const [verified, setVerified] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);

    React.useEffect(() => {
        var countryCode = user.country.code;
        form.setFieldsValue({
            country: countryCode,
            phone_number: `0${user.phone_number.slice(user.country.calling_code.length)}`,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkNumber = (rule: any, value: any, callback: any) => {
        if (!value) {
            callback();
        }
        let max = countries.find(cnt => cnt.code === form.getFieldValue('country'))?.phone_length || 0;
        if (value && value.length > max) {
            callback(`Can not be more than ${max} numbers`);
        }
        callback();
    };

    const findAccount = (v: any) => {
        setErrMsg('');
        setSubmitting(true);
        var country = form.getFieldValue('country').toLowerCase();
        v['phone_number'] = `+${countries.find(cnt => cnt.code === country)?.calling_code}${v.phone_number.replace(/^0/, '')}`;
        axius.get(`users/${v.phone_number}`, {}).then(res => {
            setSubmitting(false);
            if (res.status === 200) {
                if (res.data.id !== user.id) {
                    setErrMsg('An account with this phone number already exists.');
                } else {
                    submit(v);
                }
            } else {
                submit(v);
            }
        });
    }

    const submit = (v: any) => {
        setErrMsg('');
        setSubmitting(true);
        axius.put(`users/${user.uuid}`, v).then(res => {
            setSubmitting(false);
            if (res.status === 200) {
                props.onSuccess();
                _update.auth('user', res.data);
                presentToast('You have changed your phone number', 1500);
            } else {
                setErrMsg(res.message);
            }
        });
    }

    return (
        <React.Fragment>
            <Spin spinning={submitting} indicator={<i className="fa fa-spin fa-circle-notch" />}>
                {!verified && (
                    <Verify
                        {...props}
                        sendOtp={true}
                        v={{ phone_number: user.phone_number }}
                        onSuccess={() => {
                            setVerified(true);
                        }}
                    />
                )}

                {verified && (
                    <div>
                        <h4><b>Change phone number</b></h4>
                        <div>&nbsp;</div>

                        {errMsg && (<div className="alert alert-danger text-center">{errMsg}</div>)}

                        <Form form={form} layout="vertical" onFinish={findAccount}>
                            <Form.Item>
                                <Input.Group compact>
                                    <Form.Item name="country" noStyle initialValue={'NG'} rules={[{ required: true, message: 'Country is required' }]}>
                                        <Select style={{ width: '35%' }} size="large">
                                            {countries.map((loc: any) => (
                                                <Select.Option key={loc.code} value={loc.code}>
                                                    <small>
                                                        <img
                                                            src={`https://flagcdn.com/16x12/${loc.code.toLowerCase()}.png`}
                                                            alt={loc.name}
                                                            onError={(e: any) => e.target.src = `https://www.countryflags.io/${loc.code.toLowerCase()}/flat/16.png`}
                                                        /> &nbsp;
                                                        +{loc.calling_code}
                                                    </small>
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="phone_number" noStyle rules={[
                                        { required: true, message: 'Phone number is required' },
                                        { validator: checkNumber }
                                    ]}>
                                        <Input style={{ width: '65%' }} type="number" size="large" placeholder="* Without country code" />
                                    </Form.Item>
                                </Input.Group>
                            </Form.Item>
                            <div>&nbsp;</div>

                            <Button type="submit" variant="secondary btn-block btn-md">
                                Save
                            </Button>
                        </Form>
                    </div>
                )}
            </Spin>
        </React.Fragment>
    );
};

export default FormPhone;