import React from 'react';
import { IonLoading } from '@ionic/react';

interface ContainerProps {
    open: boolean;
    message?: string;
    onCancel: any;
    className?: any;
}

const Loading: React.FC<ContainerProps> = ({ className, open, message, onCancel }) => {

    return (
        <React.Fragment>
            <IonLoading
                isOpen={open}
                message={message || 'Please wait...'}
                cssClass={className}
                onDidDismiss={() => onCancel()}
            />
        </React.Fragment>
    );
}

export default Loading;