import axios from 'axios';
import { func } from '.';


export const apnData = (obj: any) => {
    const body = new FormData();
    for (let p in obj) {
        if (Array.isArray(obj[p])) {
            for (let i = 0; i < obj[p].length; i++) {
                body.append(`${p}[${i}]`, obj[p][i]);
            }
        } else {
            body.append(p, obj[p]);
        }
    }
    return body;
}

export const apiHeaders = (type: string = ''): any => {
    func.init();
    var token = func.getStorage('token') || '';
    if (type === 'file') {
        return {
            'Accept': 'application/json',
            'x-access-token': `${token}`
        };
    } else {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': `${token}`,
        };
    }
}

export const checkFullURL = (action: string) => {
    if (action.indexOf('http://') > -1 || action.indexOf('https://') > -1) {
        return action;
    }
    return func.config.apiURL[func.config.env as keyof typeof func.config.apiURL] + action;
}

export const get = async (action: string, data = {}) => {
    let headers = apiHeaders();
    let url = checkFullURL(action);
    return axios({
        method: 'GET', url,
        headers,
        params: data,
    }).then(response => {
        return response.data;
    }).catch(error => {
        return { status: 606, result: 'Network request failed', error };
    });
}

export const post = async (action: string, data = {}) => {
    let url = checkFullURL(action);
    let headers = apiHeaders();
    return axios({
        method: 'POST', url,
        headers,
        data,
    }).then(response => {
        return response.data;
    }).catch(error => {
        return { status: 606, result: 'Network request failed', error };
    });
}

export const put = async (action: string, data = {}) => {
    let url = checkFullURL(action);
    let headers = apiHeaders();
    return axios({
        method: 'PUT', url,
        headers,
        data,
    }).then(response => {
        return response.data;
    }).catch(error => {
        return { status: 606, result: 'Network request failed', error };
    });
}

export const delte = async (action: string, data = {}) => {
    let url = checkFullURL(action);
    let headers = apiHeaders();
    try {
        let response = await fetch(url, {
            method: 'DELETE',
            headers,
            body: JSON.stringify(data),
        });
        let responseJson = await response.json();
        return responseJson;
    } catch (error) {
        return { status: 606, result: 'Network request failed', error: error };
    }
}

export const postFile = async (action: string, data = {}) => {
    let url = checkFullURL(action);
    let headers = apiHeaders('file');
    try {
        let response = await fetch(url, {
            method: 'POST',
            headers,
            body: apnData(data),
        });
        let responseJson = await response.json();
        return responseJson;
    } catch (error) {
        return { status: 606, result: 'Network request failed', error: error };
    }
}