import React from 'react';

const Oops = () => {
    return (
        <React.Fragment>
            <div className="text-center p-4 d-flex justify-content-center align-items-center" style={{ height: '75vh' }}>
                <div>
                    <div className="primary-light fw-600 m-0" style={{ fontSize: 80 }}>Ouch!</div>
                    <div className="fw-500" style={{ fontSize: 18 }}>Sorry, we do not deliver here</div>
                    <div className="text-muted">We're expanding quickly so please do check back soon!</div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Oops;