import React from 'react';
import { RouteComponentProps } from 'react-router';
import { func, types, images, cartFx } from 'utils';
import { Button } from 'react-bootstrap';
import { CustomRadio } from 'components';
import { Input } from 'antd';
import { _store, _update } from '_Store';

interface StateProps {
    checkout: any;
    segments?: any;
    minCartValue: boolean;
}
interface Props extends RouteComponentProps, types.StateProps, StateProps { };
const Checkout: React.FC<Props> = props => {

    const { checkout, minCartValue } = props;
    const { __data: { cart, location, paymentOptions, active_venue }, } = _store.useState();

    const deliveryFee = +cart.deliveryFee;

    return (
        <React.Fragment>
            <div id="Checkout" className="p-4 content c-small">
                {location.option === 'delivery' && (
                    <>
                        <div className="primary fw-600 mb-2">Deliver To:</div>
                        <div className="p-3 alert alert-warning">
                            <div className="primary d-flex align-items-center">
                                <img src={images.Location2} alt="Location" height={15} />
                                <div className="ml-2">{location.address.name}</div>
                            </div>
                        </div>
                    </>
                )}

                {location.option === 'pickup' && (
                    <>
                        <div className="primary fw-600 mb-2">Pickup From:</div>
                        <div className="p-3 alert alert-warning">
                            <div className="primary d-flex align-items-center">
                                <img src={images.Location2} alt="Location" height={15} />
                                <div className="ml-2">{active_venue.address.name}</div>
                            </div>
                        </div>
                    </>
                )}

                <div className="primary d-flex justify-content-between fw-600 mb-2">
                    <div>Sub-Total:</div>
                    <div className="">
                        ₦{func.numberFormat(cartFx.total(cart.items))}
                    </div>
                </div>
                <div className="divider my-3" />

                {location.option === 'delivery' && (
                    <div>
                        <div className="primary d-flex justify-content-between mb-2">
                            <div>Delivery Fee:</div>
                            <div className="">
                                ₦{func.numberFormat(deliveryFee)}
                            </div>
                        </div>
                        <div className="divider my-3" />
                    </div>
                )}

                {location.option === 'delivery' && (
                    <>
                        <div className="primary fw-600 mb-2 mt-4">Delivery Instructions:</div>
                        <Input.TextArea
                            size="large"
                            rows={3} value={cart.delivery.instructions}
                            placeholder="Let the delivery person know if you have any special requests"
                            onChange={e => {
                                _update.data('cart', { ...cart, delivery: { ...cart.delivery, instructions: e.target.value, } });
                            }}
                        />
                        <div className="divider my-3" />
                    </>
                )}

                {cart.venue?.payment_options?.length > 0 && (
                    <div>
                        <div className="pb-4 mt-4">
                            <div className="primary fw-600 mb-2 mt-4">Payment Options:</div>
                            <div className="side py-2">
                                {paymentOptions.filter((po: { code: string; label: string }) => cart.venue?.payment_options.includes(po.code)).map((po: any) => (
                                    <div key={po.code} className="mx-3 my-3">
                                        <CustomRadio
                                            key={po.code}
                                            label={po.label.replace('delivery', location.option)}
                                            selected={cart.payType === po.code ? true : false}
                                            className="primary"
                                            onClick={() => {
                                                _update.data('cart', { ...cart, payType: po.code });
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="divider" />
                    </div>
                )}

                <div className="footers pb-3 mt-5">
                    <Button variant={`${minCartValue ? 'secondary' : 'gray'} btn-block btn-lg text-uppercases`} onClick={() => checkout(deliveryFee)}>
                        <div className="d-flex justify-content-between">
                            <div>Place Order</div>
                            <div><b>₦{func.numberFormat(cartFx.total(cart.items) + (location.option === 'delivery' ? deliveryFee : 0))}</b></div>
                        </div>
                    </Button>
                </div>

                <div className="text-center my-2">
                    <span className="primary fw-600 ins" onClick={() => props.history.push(`/`)}>
                        Continue Shopping
                    </span>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Checkout;
