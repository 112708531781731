import React from 'react';
import { IonContent, IonPage, useIonAlert } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { Form, Input } from 'antd';
import { Button } from 'react-bootstrap';
import { axius, func, images, types } from 'utils';
import { IonLoading, PhoneNumber } from 'components';
import { _store, _update } from '_Store';


interface Props extends RouteComponentProps, types.StateProps { };
const Login: React.FC<Props> = props => {

    const { __data: { countries, location, cart } } = _store.useState();

    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState('');

    const [presentAlert] = useIonAlert();

    React.useEffect(() => {
        if (func.config.env === 'local') {
            form.setFieldsValue({
                password: 'space123',
                phone_number: '0262988710',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submit = (v: any) => {
        setLoading('authenticating...');
        v['username'] = `+${countries.find(loc => loc.code === v.country)?.calling_code}${v.phone_number.replace(/^0/, '')}`;
        axius.post('auth', { username: v.username, password: v.password, type: 'aarin' }).then((res: types.AxiusResponse) => {
            if (res.status === 200) {
                _update.auth('user', res.data);
                _update.auth('token', res.token);
                func.init();
                if (location.address.lat) {
                    props.history.push(cart.items.length > 0 ? '/cart' : '/');
                    window.location.reload();
                } else {
                    func.setStorage('after_login', 'yes');
                    props.history.push('/location');
                }
            } else {
                if (res.status === 412) {
                    presentAlert(res.data.join('<br />'));
                } else {
                    presentAlert(res.message);
                }
            }
            setLoading('');
        });
    }

    return (
        <IonPage id="Login">
            <IonContent fullscreen={false}>
                <IonLoading open={loading ? true : false} message={loading} onCancel={() => setLoading('')} />

                <div className="p-4">
                    <img className="mb-2" src={images.Logo} alt="Chicken Republic" style={{ width: 50 }} />
                    <h2 className="mb-2"><b>Welcome Back!</b></h2>
                    <div className="text-muteds">Log in to your account.</div>
                    {/* <div>&nbsp;</div> */}

                    <Form form={form} layout="vertical" onFinish={submit} className="mt-4">
                        <PhoneNumber {...props} form={form} />
                        <Form.Item label="Password" name="password" className="mb-2" rules={[{ required: true, message: 'Field is required' },]}>
                            <Input.Password type="password" size="large" placeholder="• • • • • • • •" />
                        </Form.Item>
                        <div className="small" onClick={() => props.history.push('/reset')}>Forgot Password?</div>
                        <div>&nbsp;</div>

                        <Button type="submit" variant="secondary btn-block btn-md text-uppercases">
                            Sign in
                        </Button>
                        <div>&nbsp;</div>

                        <div className="text-centers mt-2 text-muted small" onClick={() => props.history.push('/signup')}>
                            Don't have account?&nbsp; <b className="primary">Sign up</b>
                        </div>
                    </Form>
                </div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
            </IonContent>
        </IonPage>
    );
}

export default Login;