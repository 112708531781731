/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Header, Loading } from 'components';
import { Button } from 'react-bootstrap';
import { axius, types, images } from 'utils';
import { _store, _update } from '_Store';


interface Params { id: string };
interface Props extends RouteComponentProps, types.StateProps { };
const Webhook: React.FC<Props> = props => {

    const { __data: { cartDefault } } = _store.useState();

    const path = props.history.location.pathname.split('/')[2]?.split('.') || {};
    const params: Params = { id: path[0], };

    const [data, setData] = React.useState<any>({});
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        setLoading(true);
        axius.get(`payments/${params.id}`).then((res: types.AxiusResponse) => {
            _update.data('cart', cartDefault);
            setData(res.data);
            setLoading(false);
        });
    }, []);

    return (
        <React.Fragment>
            {loading && (<Loading />)}

            {!loading && (
                <div>
                    <Header {...props} showSearch={true} showSegments={false} />

                    <div className="p-4 content c-mediums text-center">
                        <p>&nbsp;</p>
                        {data.status === 1 && (
                            <>
                                <img src={images.PaySuccess} alt="PaySuccess" />
                                <h5 className="fw-600 mt-4">Payment Successful!</h5>
                                <div className="text-muted">
                                    Your payment was successful and your order <br /> has been sent for confirmation! <br />
                                    You can track your order in the app
                                </div>
                            </>
                        )}
                        {data.status === 2 && (
                            <>
                                <img src={images.PayFailed} alt="PayFailed" />
                                <h5 className="fw-600 mt-4">Oh no, your payment failed</h5>
                                <div className="text-muted">
                                    Your transaction didn't go through. <br />
                                    Please try again.
                                </div>
                            </>
                        )}

                        <p>&nbsp;</p>
                        <p>&nbsp;</p>

                        {data.status === 1 && (
                            <Button variant="secondary btn-block btn-lg" onClick={() => props.history.push(`/user/orders/${data.data.id}`)}>
                                Track Order
                            </Button>
                        )}

                        {data.status === 2 && (
                            <Button variant="secondary btn-block btn-lg" onClick={() => props.history.push(`/cart`)}>
                                Retry
                            </Button>
                        )}

                        <div className="text-center my-4">
                            <span className="primary fw-600 ins" onClick={() => props.history.push(`/`)}>
                                Continue Shopping
                            </span>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );

}

export default Webhook;