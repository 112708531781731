import React from 'react';
import { images } from 'utils';

interface StateProps {
    open: boolean;
    title: any;
    style?: any;
    onClose: any;
    children: any;
};

interface Props extends StateProps { };

const BottomModal: React.FC<Props> = ({ onClose, title, children, style, open }) => {

    return (
        <React.Fragment>
            <div id="BottomModal" className={`${open ? 'open' : ''}`} style={style}>
                <div className={`bmodal-content animate__animated ${open ? 'animate__slideInUp' : 'animate__slideOutDown'} animate__faster`}>
                    {title && (
                        <div className="bmodal-title fw-500 fs-16 p-4 d-flex align-items-center justify-content-between">
                            <div>{title}</div>
                            <div><img className="pointer" src={images.Close} alt="Close" height="20px" onClick={() => onClose()} /></div>
                        </div>
                    )}
                    <div className="bmodal-body">
                        {children}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default BottomModal;
