import React from 'react';
import { Form, Select } from 'antd';
import moment from 'moment';


const DatePicker = props => {
    const [v, setValues] = React.useState({ day: '', month: '', year: '' });

    React.useEffect(() => {
        if (props.value) {
            let d = props.value.split('-');
            d = { day: +d[2].split('T')[0] || null, month: +d[1] || null, year: +d[0] || null };
            setValues(d);
            props.form.setFieldsValue(d);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    React.useEffect(() => {
        props.onChange(`${v.year}-${(v.month || '').toString().length > 1 ? `${v.month}` : `0${v.month}`}-${(v.day || '').toString().length > 1 ? `${v.day}` : `0${v.day}`}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [v]);

    const range = (start, end) => {
        return new Array(end - start).fill().map((d, i) => i + start);
    }

    return (
        <React.Fragment>
            <div className="d-flex justify-content-between">
                <div className="col-lg-4s" style={{ width: '32%' }}>
                    <Form.Item name="day" rules={[{ required: true, message: 'Field is required' },]}>
                        <Select size="large" placeholder="Day" onChange={day => setValues((sv) => ({ ...sv, day }))}>
                            {range(1, 32).map(day => (
                                <Select.Option key={day} value={day}>{day.toString().length === 1 ? '0' : ''}{day}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
                <div className="col-lg-4s" style={{ width: '32%' }}>
                    <Form.Item name="month" rules={[{ required: true, message: 'Field is required' },]}>
                        <Select size="large" placeholder="Month" onChange={month => setValues((sv) => ({ ...sv, month }))}>
                            {range(1, 13).map(month => (
                                <Select.Option key={month} value={month}>
                                    {moment(`${new Date().getFullYear()}-${month < 10 ? '0' : ''}${month}-01`).format('MMM')}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
                <div className="col-lg-4s" style={{ width: '32%' }}>
                    <Form.Item name="year" rules={[{ required: true, message: 'Field is required' },]}>
                        <Select size="large" placeholder="Year" onChange={year => setValues((sv) => ({ ...sv, year }))}>
                            {range(new Date().getFullYear() - 90, new Date().getFullYear() - 17).map(year => (
                                <Select.Option key={year} value={year}>{year}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
            </div>
        </React.Fragment>
    );
}


export default DatePicker;