import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { axius, types } from 'utils';
import { Empty, Header, Item, TabsOffset } from 'components';
import { _store, _update } from '_Store';

import './_User.scss';

interface Props extends RouteComponentProps, types.StateProps { };

const Favourites: React.FC<Props> = props => {
    const { __auth: { user, favourites } } = _store.useState();

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        setLoading(true);
        window.scroll(0, 0);
        axius.get(`users/${user.uuid}/favourites`, {}).then(res => {
            setLoading(false);
            if (res.status === 200) {
                setData(res.data);
                _update.auth('favourites', res.data);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <IonPage id="Favourites">
            <Header {...props} showSearch={true} />

            <IonContent fullscreen>
                <div className="p-3 text-center fw-600 title mx-4 mt-2 mt-4">
                    <span className="secondary">YOUR</span> <span className="text-white">FAVOURITES</span>
                </div>

                <div className="p-4">
                    {loading && [0, 1, 2, 3, 4].map(i => (
                        <div key={i} className="skeleton mb-4" style={{ borderRadius: 7, height: 160 }} />
                    ))}

                    {!loading && data.length === 0 && (
                        <Empty text="You have not added in items to your favourites." style={{}} />
                    )}

                    {!loading && data.length > 0 && data.map((item: types.Item) => (
                        item.status === 'published' && favourites.find(fav => fav.uuid === item.uuid) && (
                            <div key={item.id} className="mb-4">
                                <Item {...props} item={item} description={true} layout="list" />
                            </div>
                        )
                    ))}
                </div>
                <TabsOffset />
            </IonContent>
        </IonPage>
    );
};

export default Favourites;
