import React from 'react';
import { IonContent, IonPage, useIonToast } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { Form, Input, Modal, Spin } from 'antd';
import { Alert, Button } from 'react-bootstrap';
import { axius, func, images, types } from 'utils';
import { IonLoading, PhoneNumber } from 'components';
import { _store, _update } from '_Store';


const channel: 'email' | 'phone' = 'email';
const titles = {
    email: `Verify Your Email Address`,
    phone: `Verify Your Mobile Number`,
};
const labels = {
    email: `email address`,
    phone: `phone number`,
};
interface StateProps {
    title?: string;
    verify?: boolean;
    sendOtp?: boolean;
    
    onSuccess?: () => void;
}
interface Props extends RouteComponentProps, types.StateProps, StateProps { };
const Verify: React.FC<Props> = props => {

    const { sendOtp, onSuccess } = props;
    const { __data: { location, countries, cart }, } = _store.useState();

    const [form] = Form.useForm();
    const [formNumb] = Form.useForm();
    const [presentToast] = useIonToast();

    const [user, setUser] = React.useState({ uuid: '', phone_number: '', email: '' });
    const [count, setCount] = React.useState(0);
    const [errMsg, setErrMsg] = React.useState('');
    const [consent, setConsent] = React.useState(false);
    const [loading, setLoading] = React.useState('');
    const [changeNumber, setChangeNumber] = React.useState(false);

    const userTo = channel === 'email' ? user.email : user.phone_number;

    React.useEffect(() => {
        if (sendOtp) {
            setConsent(true);
        } else {
            startTimer();
        }
        setUser(func.getStorageJson('user') || { uuid: '', phone_number: '', email: '' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const startTimer = () => {
        const timer = setInterval(() => {
            setCount(prevCount => prevCount + 1);
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }

    const sendCode = async (phone = '') => {
        const to = phone || userTo;
        setErrMsg('');
        setLoading('sending OTP...');
        axius.post('otp', { to, channel }).then((res: types.AxiusResponse) => {
            if (res.status === 200) {
                if (typeof props.verify === 'undefined' || props.verify === true) {
                    setCount(0);
                    startTimer();
                    setConsent(false);
                } else {
                    // props.onSuccess ? props.onSuccess() : showScreen('signup');
                }
            } else {
                setErrMsg(res.message || 'Failed to send OTP. Please check your number and retry');
            }
            setLoading('');
        });
    }

    const submit = (v: any) => {
        if (typeof props.verify === 'undefined' || props.verify === true) {
            setErrMsg('');
            setLoading('authenticating...');
            axius.put(onSuccess ? 'otp' : 'auth/activate', { ...v, to: userTo, channel }).then((res: types.AxiusResponse) => {
                if (res.status === 200) {
                    if (onSuccess) {
                        onSuccess();
                    } else {
                        _update.auth('user', { user: res.data, token: res.token, });
                        presentToast(`Welcome, ${res.data.first_name}!`, 1500);
                        if (location.address.lat) {
                            props.history.push(cart.items.length > 0 ? '/cart' : '/');
                            window.location.reload();
                        } else {
                            func.setStorage('after_login', 'yes');
                            props.history.push('/location');
                        }
                    }
                } else {
                    setErrMsg(res.message || 'Verification failed.');
                }
                setLoading('');
            });
        } else {
            props.history.push('/location');
        }
    }

    const submitNumber = (v: any) => {
        setLoading(`changing ${labels[channel]}...`);
        v['email'] = v.email?.trim();
        v['phone_number'] = `+${countries.find(cnt => cnt.code === v.country)?.calling_code}${v.phone_number?.replace(/^0/, '')}`;
        axius.put(`users/${user.uuid}/${channel === 'email' ? 'email' : 'phone_number'}`, v).then((res: types.AxiusResponse) => {
            if (res.status === 200) {
                const sendTo = (channel === 'email' ? res.data.email : res.data.phone_number);
                setUser(res.data);
                sendCode(sendTo);
                setChangeNumber(false);
            } else {
                setErrMsg(res.message);
            }
            setLoading('');
        });
    }

    return (
        <IonPage id="Verify">
            <IonContent fullscreen={false}>
                <IonLoading open={loading ? true : false} message={loading} onCancel={() => setLoading('')} />

                <div className="p-4s">
                    {consent && (
                        <div>
                            <h4 className="primary"><b>{props.title || titles[channel]}</b></h4>
                            {errMsg && (
                                <Alert variant="danger" className="text-center">
                                    {errMsg}.
                                    {props.history.location.pathname.includes('/auth/') && (
                                        <div onClick={() => setChangeNumber(true)}>Wrong number? <span className="fw-600 ins">Change here</span></div>
                                    )}
                                </Alert>
                            )}
                            <div className="text-muted">To proceed, we need to verify you.</div>
                            <div className="text-muted">One Time Passcode (OTP) will be sent to {user.phone_number}.</div>
                            <p>&nbsp;</p>

                            <Button variant="secondary btn-block btn-md" onClick={() => sendCode()}>
                                Send OTP
                            </Button>
                        </div>
                    )}

                    {!consent && (
                        <div className="p-4">
                            <h4 className="primary"><b>{props.title || titles[channel]}</b></h4>
                            <div className="text-center">
                                <img src={images.Verify} alt="Chicken Republic" style={{ height: 200 }} className="my-5" />
                            </div>
                            <div>
                                A 6-digit code has been sent to <span className="primary">{userTo}</span>
                            </div>
                            {props.history.location.pathname.includes('/verify') && (
                                <div onClick={() => setChangeNumber(true)}>Wrong {labels[channel]}? <span className="primary ins">Change here</span></div>
                            )}
                            <div>&nbsp;</div>

                            {errMsg && (<Alert variant="danger" className="text-center">{errMsg}</Alert>)}

                            <Form form={form} layout="vertical" onFinish={submit}>
                                <Form.Item name="code" rules={[{ required: true, message: 'Code is required' },]}>
                                    <Input type="number" size="large" className="text-center" placeholder="• • • • • •" style={{ letterSpacing: 10 }} />
                                </Form.Item>
                                <div>&nbsp;</div>

                                <Button type="submit" variant="secondary btn-block btn-md">
                                    Verify
                                </Button>
                                <div>&nbsp;</div>

                                <div className="text-center">
                                    Didn't get the code? {count < 60 ? `Resend in ${60 - count} secs` : <b className="primary" onClick={() => sendCode()}>Resend now</b>}.
                                </div>
                            </Form>
                        </div>
                    )}
                </div>

                <Modal open={changeNumber} title={`Change your ${labels[channel]}`} centered footer={null} onCancel={() => setChangeNumber(false)}>
                    <Spin spinning={loading ? true : false} indicator={<span><i className="fa fa-spin fa-circle-notch" /></span>}>
                        <Form form={formNumb} layout="vertical" onFinish={submitNumber}>
                            {channel === 'email' ? (
                                <Form.Item label="Email Address" name="email" rules={[{ required: true, message: 'Field is required' },]}>
                                    <Input type="email" size="large" placeholder="arinola@gmail.com" />
                                </Form.Item>
                            ) : (
                                <PhoneNumber {...props} form={formNumb} />
                            )}
                            <div>&nbsp;</div>
                            <Button type="submit" variant="secondary btn-block btn-md text-uppercases" style={{ height: 49 }}>
                                Submit
                            </Button>
                        </Form>
                    </Spin>
                </Modal>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
            </IonContent>
        </IonPage>
    );
}

export default Verify;